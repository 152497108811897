import { sizes } from 'src/utils/consts/sizes';
import styled from 'styled-components';

export const ContainerLoginStyle = styled.div`
  overflow: auto;
  height: 100vh;

  @media screen and (${sizes.max_mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh;
  }

  @media screen and (min-width: 600px) {
    display: -webkit-grid;
    display: -moz-grid;
    display: -o-box;
    display: -ms-grid;
    display: grid;
    -ms-grid-template-columns: calc(100% - 605px) 605px;
    -o-grid-template-columns: calc(100% - 605px) 605px;
    -moz-grid-template-columns: calc(100% - 605px) 605px;
    -webkit-grid-template-columns: calc(100% - 605px) 605px;
    grid-template-columns: 50% 605px;

    min-height: 100vh;
  }
`;

export const BackgroundImageStyle = styled.img<{ src: string }>`
  height: 100%;
  width: 100%;

  background-image: url(${(props) => props.src});
  object-fit: cover;
  object-position: 0 20%;
  @media screen and (${sizes.min_tablet}) {
    object-position: left top;
  }
`;

export const HeaderStyle = styled.header`
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);

  @media screen and (min-width: 599.99px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;

  height: auto;
  width: auto;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    @media screen and (${sizes.min_tablet}) {
      display: none;
    }
  }
`;

export const SectionLoginStyle = styled.div`
  z-index: 3;
  background-color: #fff;
  padding: 24px 10px 30px 10px;
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: auto;
  @media screen and (${sizes.min_tablet}) {
    box-shadow: -5px 0px 6px -3px rgba(0, 0, 0, 0.4);
    margin-top: initial;
    margin-left: -16px;
    padding: 48px 60px 28px 60px;
    border-radius: 20px 0 0 20px;
  }
  @media screen and (${sizes.max_mobile}) and (orientation: portrait) {
    margin-top: -206px;
  }
`;

export const WrapperFormStyle = styled.div`
  max-width: 380px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (${sizes.min_tablet}) {
    max-width: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
