import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import { lazy } from 'react';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import BaseLayout from '../components/layouts/BaseLayout';
import HomeLayout from '../components/layouts/HomeLayout';
import LoginLayout from '../components/layouts/LoginLayout';
import { useAuth } from 'src/providers/AuthProvider';

const HomePage = lazy(() => import('../pages/home/HomePage'));
const LoginPage = lazy(() => import('../pages/login/LoginPage'));
const ResetPasswordPage = lazy(() => import('../pages/reset-password/ResetPasswordPage'));
const RestorePasswordPage = lazy(() => import('../pages/restore-password/RestorePasswordPage'));
const NotFoundPage = lazy(() => import('../pages/not-found/NotFoundPage'));
const PriceListPage = lazy(() => import('../pages/pricelist/PriceListPage'));
const CatalogPage = lazy(() => import('../pages/catalog/CatalogPage'));
const CatalogBrandPage = lazy(() => import('../pages/catalog-brand/CatalogBrandPage'));
const ArticlePage = lazy(() => import('../pages/articles/ArticlePage'));
const EducationPage = lazy(() => import('../pages/education/EducationPage'));
const AddCarPage = lazy(() => import('../pages/add-car/AddCarPage'));
// const ShipmentTTNPage = lazy(() => import('../pages/ShipmentTTNPage'));
const PricePage = lazy(() => import('../pages/price-generator/PriceGeneratorPage'));
const UsersPage = lazy(() => import('../pages/users/UsersPage'));
const RolesPage = lazy(() => import('../pages/roles/RolesPage'));
const ImageGeneratorPage = lazy(() => import('../pages/image-generator/ImageGeneratorPage'));
const TemplatesPage = lazy(() => import('../pages/templates/TemplatesPage'));

export default function Routes() {
  const { permissions } = useAuth();

  return (
    <ReactRoutes>
      <Route path="/" element={<HomeLayout />}>
        <Route
          index
          element={<PrivateRoute children={<HomePage />} view_permissions={['view_news']} />}
        />
        <Route
          path="pricelist"
          element={
            <PrivateRoute
              children={<PriceListPage permissions={permissions}/>}
              view_permissions={['view_eva', 'view_textiles']}
            />
          }
        >
        </Route>
        <Route
          path="catalog"
          element={
            <PrivateRoute children={<CatalogPage />} view_permissions={['view_car_catalog']} />
          }
        />
        <Route
          path="catalog/:brand"
          element={
            <PrivateRoute children={<CatalogBrandPage />} view_permissions={['view_car_catalog']} />
          }
        />
        <Route
          path="article"
          element={
            <PrivateRoute
              children={<ArticlePage />}
              view_permissions={['view_articles_generation']}
            />
          }
        />
        <Route
          path="education"
          element={<PrivateRoute children={<EducationPage />} view_permissions={[]} />}
        />
        <Route
          path="add-car"
          element={
            <PrivateRoute children={<AddCarPage />} view_permissions={['view_add_car_page']} />
          }
        />
        {/* <Route
          path="shipment-ttn"
          element={<PrivateRoute children={<ShipmentTTNPage />} view_permissions={[]} />}
        /> */}
        <Route
          path="price-goods"
          element={
            <PrivateRoute children={<PricePage />} view_permissions={['view_price_calculator']} />
          }
        />
        <Route
          path="users"
          element={
            <PrivateRoute
              children={<UsersPage />}
              view_permissions={['view_clients', 'view_employees']}
            />
          }
        />
        <Route
          path="roles"
          element={<PrivateRoute children={<RolesPage />} view_permissions={['view_roles']} />}
        />

        <Route
          path="generation-image"
          element={<PrivateRoute children={<ImageGeneratorPage />} view_permissions={['image_generator']} />}
        />

        <Route
          path="template"
          element={<PrivateRoute children={<TemplatesPage permissions={permissions} />} view_permissions={['view_product_export_templates']} />}
        />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/login" element={<PublicRoute children={<LoginPage />} />} />
      </Route>

      <Route element={<BaseLayout />}>
        <Route path="/reset-password" element={<PublicRoute children={<ResetPasswordPage />} />} />
        <Route path="/restore" element={<PublicRoute children={<RestorePasswordPage />} />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/404" element={<NotFoundPage />} />
      </Route>
    </ReactRoutes>
  );
}
