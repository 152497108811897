import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import Backdrop from 'src/components/backdrop/Backdrop';
import { LocalStorageService, ACCESS_TOKEN_KEY } from 'src/utils/localstorage';

export default function PrivateRoute({
  children,
  view_permissions,
}: {
  children: ReactNode;
  view_permissions: string[];
}) {
  const storage = new LocalStorageService();

  const { isUser, permissions, isLoading } = useAuth();
  const { state } = useLocation();

  if (!isUser && isLoading) return <Backdrop />;
  
  let access = view_permissions.length
    ? view_permissions?.some((perm) => permissions.includes(perm))
    : true;

  if (view_permissions.includes("image_generator")
    && permissions.includes('view_clients')
    && permissions.includes('view_employees')
    && permissions.includes('edit_clients')
    && permissions.includes('edit_employees')
    && permissions.includes('view_roles')
    && permissions.includes('edit_roles')) {
      access = true
  }
  
  const savedAccessToken = storage.getItem(ACCESS_TOKEN_KEY);

  return isUser || savedAccessToken ? (
    access ? (
      <>{children}</>
    ) : (
      <Navigate to={state ? state : '/404'} replace />
    )
  ) : (
    <Navigate to={state ? state : '/login'} replace />
  );
}
