import { IPropsSVGType } from '#types/svg.types';
import {
  User,
  Users,
  Settings,
  Car,
  Book,
  Add,
  Info,
  Books,
  Price,
} from '../../components/icons';

export const permissionsPages = {
  default: [
    {
      Icon: Settings,
      required: true,
      title: 'Головна',
      page: '/',
      views: ['view_news'],
      edits: ['writing_news'],
      panel: true,
    },
    {
      Icon: Book,
      required: true,
      title: 'Прайс лист',
      page: '/pricelist',
      views: ['view_eva', 'view_textiles'],
      edits: ['edit_eva', 'edit_textiles'],
      panel: false,
    },
    {
      Icon: Car,
      required: true,
      title: 'Каталог автомобілів',
      page: '/catalog',
      views: ['view_car_catalog'],
      edits: [
        'edit_car_model_number',
        'edit_car_workshop_model_number',
        'edit_car_photo',
        'edit_car_group',
        'edit_car_prefix',
        'edit_car_model',
        'edit_car_years',
        'edit_car_body_type',
        'edit_car_grade_model',
        'edit_car_type_clips',
        'edit_car_count_clips',
        'edit_car_set_mats',
        'edit_car_specification',
        'edit_car_is_leg',
        'edit_car_is_3d_leg',
        'edit_car_is_3d_shape',
        'edit_car_position_border',
        'edit_car_drop_price',
        'edit_car_price',
        'edit_car_comenth',
        'edit_car_status',
      ],
      panel: true,
    },
    {
      Icon: Books,
      required: true,
      title: 'Генератор артикулів',
      page: '/article',
      views: ['view_articles_generation'],
      edits: ['view_articles_generation'],
      panel: false,
    },
    {
      Icon: Info,
      required: false,
      title: 'Відео інструкції',
      page: '/education',
      views: [],
      edits: [],
      panel: false,
    },
    {
      Icon: Add,
      required: true,
      title: 'Додати автомобіль',
      page: '/add-car',
      views: ['view_add_car_page'],
      edits: ['edit_add_car_page'],
      panel: false,
    },
    // {
    //   Icon: Shipment,
    //   required: true,
    //   title: 'Збірка та відправка',
    //   page: '/shipment-ttn',
    //   views: ['view_logistics'],
    //   edits: ['editing_logistics'],
    //   panel: false,
    // },
    {
      Icon: Price,
      required: true,
      title: 'Генератор цін',
      page: '/price-goods',
      views: ['view_price_calculator'],
      edits: ['edit_price_calculator'],
      panel: false,
    },
  ],
  admin: [
    {
      Icon: Users,
      required: true,
      title: 'Користувачі',
      page: '/users',
      views: ['view_clients', 'view_employees'],
      edits: ['edit_clients', 'edit_employees'],
      panel: false,
    },
    {
      Icon: User,
      required: true,
      title: 'Ролі',
      page: '/roles',
      views: ['view_roles'],
      edits: ['edit_roles'],
      panel: false,
    },
  ],
};

type Pages = {
  title: string;
  required: boolean;
  views: string[];
  Icon: (props: IPropsSVGType) => JSX.Element;
  page: string;
  panel: boolean;
}[];

export const findPages = (p: Pages, perms: string[]) => {
  const pages = p.filter(({ required, views }) =>
    required ? perms.some((perm) => views.includes(perm)) : true
  );
  return pages;
};

export const adminPanel = (path: string, permissions: string[]) => {
  const panel = permissionsPages.default.some(
    (p) => p.page === path && p.panel && p.edits.some((e) => permissions.includes(e))
  );
  return panel;
};

export const getCatalogViewPermissions = (permissions: string[]): { [key: string]: number } => {
  const userPermissions: any = permissions.reduce((data, v) => ({ ...data, [v]: 1}), {});

  return {
    status: userPermissions['view_car_action'],
    action: userPermissions['view_car_action'],
    comment: userPermissions['view_car_action'],
    id: userPermissions['view_car_id'],
    car_type: userPermissions['view_car_car_type'],
    model_number:  userPermissions['view_car_model_number'],
    workshop_model_number: userPermissions['view_car_workshop_model_number'],
    grade_model: userPermissions['view_car_grade_model'],
    photo_model: userPermissions['view_car_photo_model'],
    photo_model_preview: userPermissions['view_car_preview_model'],
    photo_model_finished_product: userPermissions['view_car_photo_finished_product'],
    photo_marketplaces: userPermissions['view_car_marketplace_photos'],
    prefix: userPermissions['view_car_prefix'],
    brand: userPermissions['view_car_catalog'],
    car_model: userPermissions['view_car_catalog'],
    years: userPermissions['view_car_catalog'],
    set_mats:  userPermissions['view_car_set_mats'],
    specification: userPermissions['view_car_specification'],
    body_type:  userPermissions['view_car_catalog'],
    is_leg: userPermissions['view_car_is_leg'],
    is_3d_leg: userPermissions['view_car_is_3d_leg'],
    is_3d_shape: userPermissions['view_car_is_3d_shape'],
    photo_digit: userPermissions['view_car_photo_digit'],
    position_border_photo: userPermissions['view_car_position_border'],
    position_border: userPermissions['view_car_position_border'],
    type_clips: userPermissions['view_car_type_clips'],
    count_clips: userPermissions['view_car_count_clips'],
    price:  userPermissions['view_car_price'],
    drop_price:  userPermissions['view_car_drop_price'],
    crm_download: userPermissions['view_car_crm_download'],
    delete_car: userPermissions['delete_car']
  }
}

export const getCatalogEditPermissions = (permissions: string[]): { [key: string]: number } => {
  const userPermissions: any = permissions.reduce((data, v) => ({ ...data, [v]: 1}), {});

  return {
    checkbox: userPermissions['edit_car_checkbox'],
    status: userPermissions['edit_car_status'],
    action: userPermissions['edit_car_action'],
    comment: userPermissions['edit_car_comment'],
    id: userPermissions['view_car_id'],
    car_type: userPermissions['edit_car_car_type'],
    model_number:  userPermissions['edit_car_model_number'],
    workshop_model_number: userPermissions['edit_car_workshop_model_number'],
    grade_model: userPermissions['edit_car_grade_model'],
    photo_model: userPermissions['edit_car_photo'],
    photo_model_preview: userPermissions['edit_car_photo'],
    photo_model_finished_product: userPermissions['edit_car_photo'],
    photo_marketplaces: userPermissions['edit_car_marketplace_photos'],
    prefix: userPermissions['edit_car_prefix'],
    brand: userPermissions['view_car_catalog'],
    car_model: userPermissions['edit_car_model'],
    years: userPermissions['edit_car_years'],
    set_mats:  userPermissions['edit_car_set_mats'],
    specification: userPermissions['edit_car_specification'],
    body_type:  userPermissions['edit_car_body_type'],
    is_leg: userPermissions['edit_car_is_leg'],
    is_3d_leg: userPermissions['edit_car_is_3d_leg'],
    is_3d_shape: userPermissions['edit_car_is_3d_shape'],
    photo_digit: userPermissions['edit_car_photo_digit'],
    position_border_photo: userPermissions['edit_car_position_border'],
    position_border: userPermissions['edit_car_position_border'],
    type_clips: userPermissions['edit_car_type_clips'],
    count_clips: userPermissions['edit_car_count_clips'],
    price:  userPermissions['edit_car_price'],
    drop_price:  userPermissions['edit_car_drop_price'],
    delete_car: userPermissions['delete_car']
  }
}

export const isCatalogAdminPermissions = (permissions: string[]): boolean => {
  const viewPerms = getCatalogViewPermissions(permissions);
  const editPerms = getCatalogEditPermissions(permissions);

  const missingViewPerms = Object.values(viewPerms).filter(x => !x);

  if (missingViewPerms.length) {
    return false;
  }
  const missingEditPerms = Object.values(editPerms).filter(x => !x);

  if (missingEditPerms.length) {
    return false;
  }

  return true;
}