import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function Remove({
  width = '24',
  height = '24',
  color,
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.4903 0C14.6582 0 15.6923 0.1997 16.7665 0.7742C17.7382 1.29384 18.5061 2.06179 19.0258 3.03344C19.6003 4.10766 19.8 5.1418 19.8 7.30971L19.8 12.4903C19.8 14.6582 19.6003 15.6923 19.0258 16.7665C18.5061 17.7382 17.7382 18.5061 16.7665 19.0258C15.6923 19.6003 14.6582 19.8 12.4903 19.8L7.30971 19.8C5.1418 19.8 4.10766 19.6003 3.03344 19.0258C2.06179 18.5061 1.29384 17.7382 0.7742 16.7665C0.1997 15.6923 0 14.6582 0 12.4903L0 7.30971C0 5.1418 0.1997 4.10766 0.7742 3.03344C1.29384 2.06179 2.06179 1.29384 3.03344 0.7742C4.10766 0.1997 5.1418 0 7.30971 0ZM12.4903 1.8L7.30971 1.8C5.41353 1.8 4.66012 1.94549 3.88232 2.36146C3.22435 2.71335 2.71335 3.22435 2.36146 3.88232C1.94549 4.66012 1.8 5.41353 1.8 7.30971L1.8 12.4903C1.8 14.3864 1.94549 15.1398 2.36146 15.9177C2.71335 16.5756 3.22435 17.0866 3.88232 17.4385C4.66012 17.8545 5.41353 18 7.30971 18L12.4903 18C14.3864 18 15.1398 17.8545 15.9177 17.4385C16.5756 17.0866 17.0866 16.5756 17.4385 15.9177C17.8545 15.1398 18 14.3864 18 12.4903L18 7.30971C18 5.41353 17.8545 4.66012 17.4385 3.88232C17.0866 3.22435 16.5756 2.71335 15.9177 2.36146C15.1398 1.94549 14.3864 1.8 12.4903 1.8ZM10.799 9.00002L13.5 9.00002C13.9588 9.00002 14.3374 9.34332 14.393 9.78712L14.4 9.90002C14.4 10.3588 14.0566 10.7374 13.6129 10.793L13.5 10.8L10.799 10.8L8.99902 10.8L6.3 10.8C5.84117 10.8 5.46254 10.4566 5.40701 10.0129L5.4 9.90002C5.4 9.44122 5.74333 9.06252 6.1871 9.00702L6.3 9.00002L8.99902 9.00002Z" />
    </SVG>
  );
}
