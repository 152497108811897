import { IPropsSVGType } from '#types/svg.types';
import SVG from './SVG.styled';

export default function CarOutline({
  width = '24',
  height = '24',
  color = 'main-orange',
  stroke = '#f1b75a',
  hover,
  isHover,
  isActive,
}: IPropsSVGType) {
  return (
    <SVG
      $color={color}
      $stroke={stroke}
      $hover={hover}
      $isHover={isHover}
      $isActive={isActive}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="car_outline_24">
        <g id="Group 1000000866">
          <g id="Group 1000000865">
            <path
              id="Vector"
              d="M22.2434 11.489C21.9621 6.11644 17.3634 1.97244 11.9893 2.25644C10.2948 2.34531 8.64667 2.87719 7.2227 3.79459C6.97799 3.95228 6.90732 4.27874 7.06501 4.52371C7.22297 4.76869 7.5489 4.8391 7.79413 4.6814C9.06357 3.86342 10.5334 3.38902 12.0447 3.30965C14.3668 3.18861 16.5956 3.9776 18.323 5.53288C20.0503 7.08817 21.0687 9.22306 21.1905 11.5441C21.2909 13.4612 20.7683 15.3147 19.7077 16.8756L19.5474 15.7422C19.5065 15.4537 19.2399 15.2525 18.9512 15.2939C18.6627 15.3348 18.462 15.6016 18.5029 15.8901L18.8367 18.2494C18.838 18.2592 18.8415 18.2679 18.8433 18.2774C18.8462 18.2927 18.8496 18.3077 18.8541 18.3227C18.8594 18.3404 18.8655 18.3575 18.8723 18.3744C18.8779 18.3879 18.8831 18.401 18.8897 18.414C18.8987 18.4316 18.909 18.4482 18.9198 18.4646C18.9272 18.4757 18.9338 18.4867 18.9419 18.4973C18.9564 18.516 18.9725 18.5326 18.9891 18.5492C18.9952 18.555 18.9997 18.5619 19.006 18.5677C19.0073 18.569 19.0089 18.5698 19.0105 18.5709C19.0276 18.5859 19.0461 18.5986 19.0651 18.6115C19.073 18.6167 19.0801 18.6233 19.0883 18.6281C19.1118 18.6421 19.1368 18.6537 19.1624 18.6642C19.1735 18.6687 19.1845 18.6721 19.1959 18.6758C19.2183 18.6832 19.241 18.6903 19.2644 18.6948C19.2863 18.6988 19.3087 18.6998 19.3312 18.7009C19.3404 18.7014 19.3493 18.7035 19.3586 18.7035H19.3591C19.3594 18.7035 19.3599 18.7035 19.3602 18.7035C19.3842 18.7035 19.4084 18.7019 19.4329 18.6985L21.7891 18.3662C22.0776 18.3256 22.2782 18.0588 22.2376 17.7703C22.1968 17.4818 21.9299 17.2798 21.6417 17.3217L20.5784 17.4715C21.7693 15.72 22.3563 13.6403 22.2434 11.489Z"
              strokeWidth="0.5"
            />
            <path
              id="Vector_2"
              d="M17.9345 19.4765C17.7768 19.2315 17.4509 19.1611 17.2054 19.3185C15.936 20.1368 14.4661 20.6109 12.9551 20.6903C8.15716 20.9353 4.06088 17.2472 3.80958 12.4558C3.70911 10.5387 4.23176 8.68495 5.29235 7.12439L5.45268 8.2575C5.49012 8.52093 5.71585 8.71106 5.97427 8.71106C5.99879 8.71106 6.02384 8.70921 6.0489 8.70578C6.33738 8.66491 6.53805 8.39805 6.49718 8.10956L6.16334 5.75053C6.16202 5.74183 6.15886 5.73391 6.15728 5.72548C6.15385 5.70702 6.14937 5.68882 6.14383 5.67089C6.13908 5.65533 6.13381 5.64057 6.12774 5.6258C6.12089 5.60892 6.11297 5.59257 6.10427 5.57622C6.09663 5.56198 6.08871 5.54853 6.07975 5.53509C6.06973 5.51979 6.05865 5.50502 6.04679 5.49079C6.0365 5.47839 6.02595 5.46679 6.01462 5.45545C6.00723 5.44807 6.00169 5.43963 5.99405 5.43277C5.98719 5.42671 5.97928 5.42249 5.97216 5.41669C5.96082 5.40746 5.94922 5.39928 5.93709 5.39111C5.91968 5.37924 5.90228 5.36817 5.88409 5.35867C5.87301 5.35287 5.86167 5.34812 5.85007 5.34311C5.83029 5.33441 5.81025 5.3265 5.78968 5.3207C5.77729 5.31701 5.76463 5.31437 5.75197 5.31173C5.73167 5.30752 5.71163 5.30356 5.69106 5.30171C5.67656 5.3004 5.66232 5.30013 5.64755 5.29987C5.62804 5.2996 5.60879 5.30013 5.58954 5.30198C5.58215 5.30277 5.57477 5.30171 5.56739 5.30277L3.21125 5.63424C2.92277 5.67485 2.72183 5.94171 2.76244 6.22993C2.80278 6.51841 3.07017 6.71803 3.35813 6.67874L4.42162 6.52896C3.23076 8.27965 2.64351 10.3597 2.75637 12.5109C3.02877 17.7108 7.34576 21.7572 12.4939 21.7572C12.6651 21.7572 12.8375 21.7525 13.0105 21.7435C14.7048 21.6546 16.3531 21.1228 17.7771 20.2054C18.0218 20.0477 18.0922 19.7212 17.9345 19.4765Z"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </SVG>
  );
}
